<template>
  <div v-if="item" class="catalog-banner__item-caption">
    <p
      class="catalog-banner__item-discount"
      :class="{ best: item.attributes.best_price }"
    >
      <img
        v-if="item.attributes.best_price"
        class="catalog-banner__item-icon"
        src="@/assets/img/icons/best_price-icon-white.svg"
        alt=""
      />
      {{ getDiscount(item) }}
    </p>
    <div
      class="catalog-banner__item-price"
      :class="{ 'special-price': item.attributes.best_price }"
    >
      <span
        class="old-price"
        :class="{
          'social-price': item.attributes.best_price,
        }"
      >
        {{ getBestPrice(item) }}
      </span>
      <span
        class="new-price"
        :class="{ 'best-price': item.attributes.best_price }"
        >{{ item.attributes.final_price }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerCardCaption",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getPrice(item) {
      return String(item.attributes.final_price).trim().length;
    },
    getBestPrice(item) {
      if (item.attributes.best_price) {
        return this.$i18n.locale === "ru"
          ? "Социальная цена"
          : "Әлеуметтік баға";
      }
      return item.attributes.start_price + " ";
    },
    getDiscount(item) {
      if (!item.attributes.best_price) {
        return "-" + parseInt(item.attributes.discount * 100) + "%";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
